import axios from 'axios'
import qs from 'qs'
import sign from './sign'
import { showToast } from 'vant';

const instance = axios.create({
  timeout: 15000,
  withCredentials: false,
  baseURL: '/',
})

instance.defaults.headers['content-type'] = 'application/x-www-form-urlencoded'
instance.interceptors.request.use(
  (config) => {
    sign(config)
    if (config.url != '/api/cat/v1/ability/upload') {
      config.data = qs.stringify(config.data)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (res) => {
    if (res.status === 200 && res.data.code == 'success') {
      return res.data
    } else {
      return Promise.reject(res.data)
    }
  },
  (error) => {
    if (error?.message == 'Request failed with status code 401') {
      localStorage.removeItem('token')
      location.href = '/login'
    }

    return Promise.reject(error)
  },
)

function request(config = {}) {
  return new Promise((resolve, reject) => {
    instance
      .request(config)
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        if (error.code == 'ERR_BAD_RESPONSE') {
          showToast('服务器异常')
          
        }
        return reject(error)
      })
  })
}
export function post(url, data = {}, config = {}) {
  const conf = Object.assign({}, config, {
    method: 'post',
    url,
    data,
  })
  return request(conf)
}
export function get(url, params = {}, config = {}) {
  const conf = Object.assign({}, config, {
    method: 'get',
    url,
    params,
  })
  return request(conf)
}
export function put(url, data = {}, config = {}) {
  const conf = Object.assign({}, config, {
    method: 'put',
    url,
    data,
  })
  return request(conf)
}
export function del(url, params = {}, config = {}) {
  const conf = Object.assign({}, config, {
    method: 'delete',
    url,
    params,
  })
  return request(conf)
}
// export function upload(url, data = {}, config = {}) {
//   const conf = Object.assign({}, config, {
//     method: 'post',
//     url,
//     data,
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
//   return request(conf)
// }
//
export function login(data = {}) {
  return post('/api/cat/v1/auth/login', data)
}

export function getUserInfo(data = {}) {
  return post('/app/cat/v1/user/detail', data)
}
export function getAddress(data){
  return  post('/app/cat/v1/user/address/detail',data)
}
export function updateAddress(data){
  return  post('/app/cat/v1/user/address/update',data)
}
export  function updateBank(data){
  return  post('/app/cat/v1/user/bank/update',data)
}
export  function getBankDetail(){
  return Promise.all([post('/app/cat/v1/user/bank/detail'),post('/app/cat/v1/bank/views')])
}
export  function updatePassword(data){
  return  post('/app/cat/v1/user/password',data)
}
export  function updateSecurity(data){
  return  post('/app/cat/v1/user/security',data)
}
export function getAssets(data){
  return post('/app/cat/v1/trans/asset/list',data)
}
export function submitWithdraw(data){
  return post('/app/cat/v1/trans/withdraw/submit',data)
}
export function getWithdrawRecord(data){
  return post('/app/cat/v1/trans/withdraw/list',data)
}

export function getOrderStatistic(data){
  return post('/app/cat/v1/order/statistic',data)
}
export function getOrder(data){
  return post('/app/cat/v1/order/list',data)
}
export function getOrderDetail(data){
  return post('/app/cat/v1/order/detail',data)
}
export function submitOrder(data){
  return post('/app/cat/v1/order/submit/order',data)
}
//
export function grabOrder(data){
  return post('/app/cat/v1/order/grab/order',data)
}
//
export function getCsv(data){
  return post('/app/cat/v1/trans/csv',data)
}
export function getVips(){
  return post('/app/cat/v1/user/vips',{
    page:0,
    size:100
  })
}
//
export function registerApi(data){
  return post('/api/cat/v1/auth/register',data)
}

export function loginOut(){
  return post('/api/cat/v1/auth/login_out')
}
export function upload(formData) {

  return post('/api/cat/v1/ability/upload',formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export function getBase(){
  return post('/app/cat/v1/trans/control')
}

// axios.post('/api/uploadFile', formData, {
//   headers: {
//     'Content-Type': 'multipart/form-data'
//   }
// }).then(function (response) {
//   console.log(response);
// }).catch(function (error) {
//   console.log(error);
// });